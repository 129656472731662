document.addEventListener("turbo:load", () => {
  const cost_plus_number_selectors = document.querySelectorAll('input[data-cost-plus-discount-rule-id]');

  cost_plus_number_selectors.forEach((selector) => {
    selector.addEventListener('change', async (event) => {
      const selector = event.target;
      const updateUrl = selector.dataset.updateUrl;
      const method = selector.dataset.method || 'get';
      const newValue = selector.value;

      const urlWithParams = new URL(updateUrl, window.location.origin);
      urlWithParams.searchParams.append('cost_plus', newValue);

      try {
        const response = await fetch(urlWithParams, {
          method: method.toUpperCase(),
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          }
        });

        if (!response.ok) {
          console.error('Failed to update state_taxes_enabled:', await response.text());
        }
      } catch (error) {
        console.error('Error updating state_taxes_enabled:', error);
      }
    });
  });

  const retail_minus_number_selectors = document.querySelectorAll('input[data-retail-minus-discount-rule-id]');

  retail_minus_number_selectors.forEach((selector) => {
    selector.addEventListener('change', async (event) => {
      const selector = event.target;
      const updateUrl = selector.dataset.updateUrl;
      const method = selector.dataset.method || 'get';
      const newValue = selector.value;

      const urlWithParams = new URL(updateUrl, window.location.origin);
      urlWithParams.searchParams.append('retail_minus', newValue);

      try {
        const response = await fetch(urlWithParams, {
          method: method.toUpperCase(),
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          }
        });

        if (!response.ok) {
          console.error('Failed to update state_taxes_enabled:', await response.text());
        }
      } catch (error) {
        console.error('Error updating state_taxes_enabled:', error);
      }
    });
  });

  const rule_strategy_selectors = document.querySelectorAll('select[data-discount-rule-id]');

  rule_strategy_selectors.forEach((selector) => {
    selector.addEventListener('change', async (event) => {
      const selector = event.target;
      const updateUrl = selector.dataset.updateUrl;
      const method = selector.dataset.method || 'get';
      const selectedValue = selector.value;

      const urlWithParams = new URL(updateUrl, window.location.origin);
      urlWithParams.searchParams.append('rule_strategy', selectedValue);

      try {
        const response = await fetch(urlWithParams, {
          method: method.toUpperCase(),
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          }
        });

        if (!response.ok) {
          console.error('Failed to update rule_strategy:', await response.text());
        } else {
          window.location.href = response.url;
        }
      } catch (error) {
        console.error('Error updating rule_strategy:', error);
      }
    });
  });

  const source_selectors = document.querySelectorAll('select[data-source-discount-rule-id]');

  source_selectors.forEach((selector) => {
    selector.addEventListener('change', async (event) => {
      const selector = event.target;
      const updateUrl = selector.dataset.updateUrl;
      const selectedValue = selector.value;
      const ruleType = selector.dataset.ruleType;

      const urlWithParams = new URL(updateUrl, window.location.origin);

      if (ruleType == 'by_state') {
        urlWithParams.searchParams.append('state_name', selectedValue);
      } else {
        urlWithParams.searchParams.append('location_id', selectedValue);
      }

      try {
        const response = await fetch(urlWithParams, {
          method: 'get',
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          }
        });

        if (!response.ok) {
          console.error('Failed to update source:', await response.text());
        }
      } catch (error) {
        console.error('Error updating source:', error);
      }
    });
  });
});
