import tailSelect from "tail.select.js";

document.addEventListener("turbo:load", () => {
  const searchFiltersForm = document.getElementById("discount-policies-filters-form");

  if (!searchFiltersForm) return;

  const stateSelect = document.querySelector("select#state");
  const stateSelectInstanceName = stateSelect.getAttribute("data-tail-select");
  const cachedSelectInstance = tailSelect.inst[stateSelectInstanceName];
  const searchInput = document.getElementById("search-input");

  if (cachedSelectInstance) return;

  const stateSelectInstance = tailSelect(stateSelect, {
    placeholder: "Search by status",
    multiple: true,
    multiContainer: true,
    multiShowCount: false
  });

  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => { callback(...args); }, wait);
    };
  };

  const handleSearchInputChange = debounce(() => { searchFiltersForm.requestSubmit(); }, 600);
  const handleSelectCustomerChange = () => { searchFiltersForm.requestSubmit(); }

  searchInput.addEventListener("input", handleSearchInputChange);
  stateSelectInstance.on("change", handleSelectCustomerChange);

  document.addEventListener("turbo:before-fetch-request", (e) => {
    const url = e.detail.url;
    const query = url.searchParams.get('q');

    if (!query) url.searchParams.delete('q');
  });
})
