import tailSelect from "tail.select.js";

document.addEventListener("turbo:load", () => {
  const filtersForm = document.getElementById("trips-filters-form");

  if (!filtersForm) return;

  const customerSelect = document.querySelector("select#customer_id");
  const customerSelectInstanceName = customerSelect.getAttribute("data-tail-select");
  const cachedSelectInstance = tailSelect.inst[customerSelectInstanceName]
  const searchInput = document.getElementById("search-input");

  // check if page was cached with initialized tailSelect instances
  // if cache exists, we don't need to do initialization again and setup listeners
  if (cachedSelectInstance) return;

  const customerSelectInstance = tailSelect(customerSelect, {
    placeholder: "Search by owner",
    multiple: true,
    multiContainer: true,
    multiShowCount: false
  });

  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback(...args);
      }, wait);
    };
  };

  const handleSearchInputChange = debounce(() => {
    filtersForm.requestSubmit();
  }, 1000);

  const handleSelectCustomerChange = () => {
    filtersForm.requestSubmit();
  }

  searchInput.addEventListener("input", handleSearchInputChange);
  customerSelectInstance.on("change", handleSelectCustomerChange);

  // delete q parameter from url if its value is empty before sending turbo request
  document.addEventListener("turbo:before-fetch-request", (e) => {
    const url = e.detail.url;
    const query = url.searchParams.get('q');

    if (!query) url.searchParams.delete('q');
  });
})
