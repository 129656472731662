document.addEventListener("turbo:load", () => {
  const checkboxes = document.querySelectorAll('input[data-pricing-policy-id]');

  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener('change', async (event) => {
      const checkbox = event.target;
      const updateUrl = checkbox.dataset.updateUrl;
      const method = checkbox.dataset.method || 'patch';

      try {
        checkbox.disabled = true;
        const response = await fetch(updateUrl, {
          method: method.toUpperCase(),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          },
          body: JSON.stringify({ state_taxes_enabled: checkbox.checked }),
        });
        checkbox.disabled = false;

        if (!response.ok) {
          console.error('Failed to update state_taxes_enabled:', await response.text());
        }
      } catch (error) {
        console.error('Error updating state_taxes_enabled:', error);
      }
    });
  });
});
