import tailSelect from "tail.select.js";

document.addEventListener("turbo:load", () => {
  const searchFiltersForm = document.getElementById("carriers-filters-form");

  if (!searchFiltersForm) return;

  const customerSelect = document.querySelector("select#source_type");
  const customerSelectInstanceName = customerSelect.getAttribute("data-tail-select");
  const cachedSelectInstance = tailSelect.inst[customerSelectInstanceName]
  const searchInput = document.getElementById("search-input");

  if (cachedSelectInstance) return;

  const customerSelectInstance = tailSelect(customerSelect, {
    placeholder: "Search by source",
    multiple: true,
    multiContainer: true,
    multiShowCount: false
  });

  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => { callback(...args); }, wait);
    };
  };

  const handleSearchInputChange = debounce(() => { searchFiltersForm.requestSubmit(); }, 600);
  const handleSelectCustomerChange = () => { searchFiltersForm.requestSubmit(); }

  searchInput.addEventListener("input", handleSearchInputChange);
  customerSelectInstance.on("change", handleSelectCustomerChange);

  document.addEventListener("turbo:before-fetch-request", (e) => {
    const url = e.detail.url;
    const query = url.searchParams.get('q');

    if (!query) url.searchParams.delete('q');
  });
})
