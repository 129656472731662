document.addEventListener("turbo:load", () => {
  const modal = document.getElementById('modal');
  const openModalButton = document.getElementById('open-modal');
  const closeModalButton = document.getElementById('close-modal');

  if (!modal || !openModalButton || !closeModalButton) {
    return
  }

  openModalButton.addEventListener('click', () => {
    modal.classList.remove('hidden');
  });

  closeModalButton.addEventListener('click', () => {
    modal.classList.add('hidden');
  });

  modal.addEventListener('click', (event) => {
    if (event.target === modal) {
      modal.classList.add('hidden');
    }
  });

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('open_modal') && urlParams.get('open_modal') === 'true') {
    modal.classList.remove('hidden');
    urlParams.delete('open_modal');
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    history.replaceState(null, '', newUrl);
  }
});
