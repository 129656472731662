document.addEventListener("turbo:load", () => {
  const modal = document.getElementById('add_rule_modal');
  const openModalButton = document.getElementById('open_add_rule_modal');
  const closeModalButton = document.getElementById('close_add_rule_modal');
  const ruleTypeDropdown = document.getElementById('rule_type');
  const dynamicDropdown = document.getElementById('dynamic_dropdown');

  if (!modal || !openModalButton || !closeModalButton || !ruleTypeDropdown || !dynamicDropdown) {
    return;
  }

  const states = JSON.parse(dynamicDropdown.dataset.states);
  const locations = JSON.parse(dynamicDropdown.dataset.locations);
  const one9Locations = JSON.parse(dynamicDropdown.dataset.one9Locations);
  const expressLocations = JSON.parse(dynamicDropdown.dataset.expressLocations);

  const populateDynamicDropdown = (options, isState = false) => {
    dynamicDropdown.innerHTML = '';

    options.forEach(option => {
      const opt = document.createElement('option');
      opt.value = isState ? option.state_name : option.id;
      opt.textContent = option.name;
      dynamicDropdown.appendChild(opt);
    });
  };

  openModalButton.addEventListener('click', () => {
    modal.classList.remove('hidden');
  });

  closeModalButton.addEventListener('click', () => {
    modal.classList.add('hidden');
  });

  ruleTypeDropdown.addEventListener('change', () => {
    const selectedType = ruleTypeDropdown.value;
    if (selectedType === 'by_state') {
      populateDynamicDropdown(states, true); // Indicate it's for states
    } else if (selectedType === 'by_location') {
      populateDynamicDropdown(locations);
    } else if (selectedType === 'is_one9') {
      populateDynamicDropdown(one9Locations);
    } else if (selectedType === 'is_express') {
      populateDynamicDropdown(expressLocations);
    }
  });

  populateDynamicDropdown(states, true); // Default to states on load
});
