import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Dynamically import all controllers in the directory
const controllers = import.meta.glob("./*.js");

for (const [path, controller] of Object.entries(controllers)) {
  const controllerName = path
    .replace("./", "")
    .replace(".js", "")
    .replace("_controller", "");
  controller().then((module) => {
    application.register(controllerName, module.default);
  });
}
