import tailSelect from "tail.select.js";

document.addEventListener("turbo:load", () => {
  const searchFiltersForm = document.getElementById("customers-filters-form");

  if (!searchFiltersForm) return;

  const customerRoleSelect = document.querySelector("select#customer_role");
  const customerRoleSelectInstanceName = customerRoleSelect.getAttribute("data-tail-select");
  const cachedSelectInstance = tailSelect.inst[customerRoleSelectInstanceName]

  const customersCarrierSelect = document.querySelector("select#carrier_name");
  const customersCarrierSelectInstanceName = customersCarrierSelect.getAttribute("data-tail-select");
  const cachedCarrierSelectInstance = tailSelect.inst[customersCarrierSelectInstanceName]

  const searchInput = document.getElementById("search-input");

  if (cachedSelectInstance && cachedCarrierSelectInstance) return;

  const customerRoleSelectInstance = tailSelect(customerRoleSelect, {
    placeholder: "Search by role",
    multiple: true,
    multiContainer: true,
    multiShowCount: false
  });

  const customersCarrierSelectInstance = tailSelect(customersCarrierSelect, {
    placeholder: "Search by carrier",
    multiple: true,
    multiContainer: true,
    multiShowCount: false
  });

  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => { callback(...args); }, wait);
    };
  };

  const handleSearchInputChange = debounce(() => { searchFiltersForm.requestSubmit(); }, 600);
  const handleSelectCustomerChange = () => { searchFiltersForm.requestSubmit(); }

  searchInput.addEventListener("input", handleSearchInputChange);
  customerRoleSelectInstance.on("change", handleSelectCustomerChange);
  customersCarrierSelectInstance.on("change", handleSelectCustomerChange);

  document.addEventListener("turbo:before-fetch-request", (e) => {
    const url = e.detail.url;
    const query = url.searchParams.get('q');

    if (!query) url.searchParams.delete('q');
  });
})
