import "@hotwired/turbo-rails";
import "~/entrypoints/application.css";
import "./admin/admin.js";
import "./admin/controllers/stimulus_config.js";

import Plausible from "plausible-tracker";
import L from "leaflet";
import "polyline-encoded";
import tailSelect from "tail.select.js";

const { trackPageview } = Plausible({
  domain: document.location.hostname,
});

document.addEventListener("turbo:load", () => {
  trackPageview();
});

const setupDiscountTypeFieldsComponent = () => {
  const discountTypeFields = document.querySelectorAll(".discount-type-field");
  const discountTypeIds = Array.from(discountTypeFields).map((s) =>
    s.getAttribute("id")
  );
  discountTypeIds.map((id) => {
    const discountTypes = tailSelect(`#${id} select.discount-type-strategy`, {
      classNames: ["w-full"],
    });
    const locationTypes = tailSelect(
      `#${id} select.discount-type-location-strategy`,
      {
        search: true,
        classNames: ["w-full"],
      }
    );
    const stateTypes = tailSelect(
      `#${id} select.discount-type-state-strategy`,
      {
        classNames: ["w-full"],
      }
    );
    [
      ...(Array.isArray(locationTypes) ? locationTypes : [locationTypes]),
      ...(Array.isArray(stateTypes) ? stateTypes : [stateTypes]),
      ...(Array.isArray(discountTypes) ? discountTypes : [discountTypes]),
    ].map((s) => {
      if (!s) {
        return;
      }
      s.on("change", (item, state) => {
        const form = document.querySelector("form#new-discount-policy");
        const button = document.querySelector(`#${id} button`);
        form.requestSubmit(button);
      });
    });

    const inputNumbers = document.querySelectorAll(
      `form#new-discount-policy #${id} input[type="number"]`
    );

    Array.from(inputNumbers).map((input) => {
      input.addEventListener("change", (event) => {
        const form = document.querySelector("form#new-discount-policy");
        const button = document.querySelector(`#${id} button`);
        form.requestSubmit(button);
      });
    });
  });
};

document.addEventListener("turbo:load", setupDiscountTypeFieldsComponent);
document.addEventListener("turbo:frame-load", setupDiscountTypeFieldsComponent);

document.addEventListener("turbo:load", () => {
  const select = tailSelect("select#discount_policy_chain_id", {
    classNames: ["w-1/3"],
    multiple: true,
    multiContainer: true
  });

  if (!select) {
    return;
  }

  const form = document.querySelector("form#new-discount-policy");
  const createElement = (tagName, attrs = {}) => {
    const el = document.createElement(tagName);
    return Object.entries(attrs).reduce((element, attr) => {
      const [attrName, attrValue] = attr;
      element.setAttribute(attrName, attrValue);
      return element;
    }, el);
  };
  const selectDiscountPolicyChain = document.querySelector('select#discount_policy_chain_id');
  const selectedOptions = selectDiscountPolicyChain.querySelectorAll('option:checked');
  const selectedChainIds = Array.from(selectedOptions, o => o.value)

  const selectState = {
    select: selectedChainIds,
    unselect: [],
  };
  const createHiddenFields = (selectState, state) => {
    const chainsDoc = document.createDocumentFragment();
    const id = `${state}-chains`;
    const chainFields = createElement("fieldset", {
      id,
    });
    const hiddenFields = selectState[state].map((chainId) => {
      return createElement("input", {
        type: "hidden",
        name: `${state}_chain_id[]`,
        value: chainId,
      });
    });
    hiddenFields.map((f) => chainFields.append(f));
    chainsDoc.appendChild(chainFields);
    const previousFields = document.querySelector(`#${id}`);
    if (previousFields) {
      form.removeChild(previousFields);
    }
    form.append(chainsDoc);
  };

  select.on("change", (item, state) => {
    selectState[state].push(item.key);
    const oppositeState = state === "select" ? "unselect" : "select";
    const index = selectState[oppositeState].indexOf(item.key);
    if (index > -1) {
      selectState[oppositeState].splice(index, 1);
    }

    createHiddenFields(selectState, "select");
    createHiddenFields(selectState, "unselect");

    form.requestSubmit();
  });
});

document.addEventListener("turbo:load", () => {
  if (!!document.querySelector("#trip-map")) {
    const tripMap = L.map("trip-map");
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a>',
    }).addTo(tripMap);

    const mapContainer = document.querySelector("#trip-map");

    const polyline = mapContainer.dataset.polyline;
    const line = L.Polyline.fromEncoded(polyline);
    tripMap.fitBounds(line.getBounds());
    line.addTo(tripMap);

    const selectedStations = JSON.parse(mapContainer.dataset.selected);
    const candidateStations = JSON.parse(mapContainer.dataset.candidates);

    const allLocations = JSON.parse(mapContainer.dataset.allLocations);
    const radius = 5;

    const notCandidateIds = [...candidateStations.map((station) => station.id)];
    const notSelectedIds = [...selectedStations.map((station) => station.id)];
    const notAllLocationIds = [...notSelectedIds, ...notCandidateIds];

    allLocations
      .filter((s) => !notAllLocationIds.includes(s.id))
      .map((station) => {
        const circle = L.circleMarker([station.latitude, station.longitude], {
          color: "gray",
          borderOpacity: 0.4,
          fillColor: "gray",
          fillOpacity: 0.3,
          radius,
        });
        circle.bindPopup(
          `${station.name}, ${station.city}, ${station.state_code}`
        );
        return circle;
      })
      .forEach((circle) => {
        circle.addTo(tripMap);
      });

    candidateStations
      .filter((station) => !notSelectedIds.includes(station.id))
      .map((station) => {
        const circle = L.circleMarker([station.latitude, station.longitude], {
          color: "deeppink",
          fillColor: "deeppink",
          fillOpacity: 0.5,
          radius,
        });
        circle.bindPopup(
          `${station.name}, ${station.city}, ${station.state_code}`
        );
        return circle;
      })
      .forEach((circle) => {
        circle.addTo(tripMap);
      });

    selectedStations
      .map((station) => {
        const circle = L.circleMarker([station.latitude, station.longitude], {
          color: "darkblue",
          fillColor: "darkblue",
          fillOpacity: 0.5,
          radius,
        });

        circle.bindPopup(
          `${station.name}, ${station.city}, ${station.state_code}`
        );

        return circle;
      })
      .forEach((circle) => {
        circle.addTo(tripMap);
      });
  }
});
